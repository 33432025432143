import React, { useContext, useState } from 'react';
import ReactLoading from 'react-loading';
import { triggerBase64Download } from 'common-base64-downloader-react';

import { SearchBox, SearchRow } from '../styles';
import { FormRow, SubmitButton } from '../../Entries/Patients/styles';
import { danger, info } from '../../../../components/Alerts';
import { GlobalContext } from '../../../../GlobalContext';
import { getDadosGeraisBeneficiarios } from '../../../../utils/api';

export default function GeralDataBeneficiaries() {
  const [isLoading, setIsLoading] = useState(false);
  const { actions, currentUser } = useContext(GlobalContext);
  const territories = [
    'Todas', 'Alagoinha', 'Belo Jardim', 'Cachoeirinha', 'Pesqueira', 'Poção', 'Sanharó', 'São Bento do Una', 'Tacaimbó'
  ]
  const status = [
    {label: 'Apenas Ativos', value: '0'},
    {label: 'Apenas Inativos', value: '1'},
    {label: 'Todos', value: '-1'},
  ]
  const sectors = [
    {label: 'Terapeutico Ambulatorial', value: '2'},
    {label: 'Centro Auditivo', value: '1'},
  ]
  const [territory, setTerritory] = useState('Todas');
  const [selectedStatus, setSelectedStatus] = useState('0');
  const [selectedSector, setSelectedSector] = useState('2');

  function handleSubmit(e) {
    e.preventDefault();
    if(!isLoading) {
      setIsLoading(true);
        getDadosGeraisBeneficiarios(territory, currentUser.id, selectedStatus, selectedSector).then(json => {
          if (json.error) {
            danger(`${json.message}`);
          } else {
            triggerBase64Download(json.data.base64_string, json.data.file_name.replace('.pdf', ''));
          }
          setIsLoading(false);
        });
    }
  }

  return (
    <div className="animeUp">
      <h2 className="section">DADOS GERAIS DOS BENEFICIÁRIOS</h2>
      <form disabled={!actions.some(action => action.id === 1022)}>
        <SearchBox width="50%">
            <div className="mediumInput">
              <label className="main">Cidade: </label>
              <select value={territory} onChange={e => setTerritory(e.target.value)}>
                {territories.map(el => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </select>
            </div>
            <div className="mediumInput">
              <label className="main">Setor: </label>
              <select value={selectedSector} onChange={e => setSelectedSector(e.target.value)}>
                {sectors.map(sector => 
                  <option value={sector.value} key={sector.value}>
                    {sector.label}
                  </option>
                )}
              </select>
            </div>
              {selectedSector != '1' &&
                <div className="mediumInput animeShow">
                  <label className="main">Situação: </label>
                  <select value={selectedStatus} onChange={e => setSelectedStatus(e.target.value)}>
                    {status.map(el => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </select>
                </div>
              }
            <SubmitButton primary onClick={e => handleSubmit(e)}>
              {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : 'Gerar relatório'
              }
            </SubmitButton>
          {/* {territory === 'Todas' && 
            <FormRow className='animeUp'>
              <div className="fullInput radioGroup" style={{ marginLeft: -5 }}>
                <div className="radioGroupRow">
                  <label>
                    <input type="checkbox" checked={allCities} onChange={() => setAllCities(!allCities)} />
                    <span className="checkMark" style={{ marginLeft: 5 }}></span>Incluir cidades fora da 8º microregião de saúde
                  </label>
                </div>
              </div>
            </FormRow>
          } */}

        </SearchBox>
      </form>
    </div>
  );
}
