import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import ReactLoading from 'react-loading';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { HiUserRemove } from 'react-icons/hi';

import RadioOrCheck from '../../../../../components/RadioOrCheck';
import { RemoveButton, FamiliarInput, FamiliarType } from '../styles';
import { FormField, FormRow, SubmitButton, TitleSection } from '../../../Entries/Patients/styles';
import {
  AddButton,
  AttendanceRow,
  EmptyContent,
  RadioRow,
  Select
} from '../../Therapeutic Project/styles';
import { danger, success } from '../../../../../components/Alerts';
import { useNavigate } from 'react-router-dom';
import addZero from '../../../../../utils/addZero';
import { setReavaliacao } from '../../../../../utils/api';
import { GlobalContext } from '../../../../../GlobalContext';

export default function FormRevaluation({
  selectedProfessionals,
  professionals,
  selectedPatient,
  relationships,
  areas,
  selectedAttendances,
  setSelectedAttendances,
  setSelectedProfessionals,
  selectedFamiliars,
  selectedProfessional,
  selectedFamiliar,
  selectedAttendance,
  setSelectedFamiliars,
  selectedProfessionals2,
  initialValues,
  setInitialValues,
  setSelectedProfessionals2,
  setSelectedFamiliar,
  setSelectedAttendance,
  frequencies,
  setSelectedProfessional,
  responses,
  print,
  editDate
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { actions } = useContext(GlobalContext);
  const currentDate = new Date();
  const hours = [
    { value: '08', label: '8' },
    { value: '09', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' }
  ];
  const minutes = [
    { value: '00', label: '00' },
    { value: '05', label: '05' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '35', label: '35' },
    { value: '40', label: '40' },
    { value: '45', label: '45' },
    { value: '50', label: '50' },
    { value: '55', label: '55' }
  ];

  function handleAddAttendance(isAttendance) {
    if (!isAttendance) {
      const have = selectedProfessionals.some(el => el.id == selectedProfessional);
      if (selectedProfessional && !have) {
        let array = [...selectedProfessionals];
        const newProfessional = professionals.find(el => el.id == selectedProfessional);
        newProfessional && array.push(newProfessional);
        setSelectedProfessionals(array);
      }
    } else {
      const have = selectedAttendances.some(el => el.id == selectedAttendance);
      if (selectedAttendance && !have) {
        let array = [...selectedAttendances];
        const newAttendance = {
          ...areas.find(el => el.id == selectedAttendance),
          frequency: null,
          day: '2',
          initialHour: '08',
          initialMinute: '00',
          finalHour: '09',
          finalMinute: '00'
        };
        newAttendance && array.push(newAttendance);
        setSelectedAttendances(array);
      }
    }
  }

  function handleRemoveAttendance(el, isAttendance) {
    if (!isAttendance) {
      let array = [...selectedProfessionals];
      array.splice(array.indexOf(el), 1);
      setSelectedProfessionals(array);
    } else {
      let array = [...selectedAttendances];
      array.splice(array.indexOf(el), 1);
      setSelectedAttendances(array);
    }
  }

  function handleRemoveFamiliar(familiar) {
    let array = [...selectedFamiliars];
    array.splice(array.indexOf(familiar), 1);
    setSelectedFamiliars(array);
  }

  function handleAddFamiliar() {
    const have = selectedFamiliars.some(el => el.id == selectedFamiliar);
    if (selectedFamiliar && !have) {
      let array = [...selectedFamiliars];
      const newFamiliar = { ...relationships.find(el => el.id == selectedFamiliar), name: '' };
      array.push(newFamiliar);
      setSelectedFamiliars(array);
    }
  }

  function handleChangeFamiliar(familiar, newValue) {
    const familiars = [...selectedFamiliars];
    const index = familiars.findIndex(element => element === familiar);
    familiars[index].name = newValue;
    setSelectedFamiliars(familiars);
  }

  function handleChangeAttendance(attendance, field, newValue) {
    const attendances = [...selectedAttendances];
    const index = attendances.findIndex(element => element === attendance);
    attendances[index][field] = newValue;
    setSelectedAttendances(attendances);
  }

  function handleAddProfessional() {
    const have = selectedProfessionals2.some(el => el.id == selectedProfessional);
    if (selectedProfessional && !have) {
      let array = [...selectedProfessionals2];
      const newProfessional = professionals.find(el => el.id == selectedProfessional);
      array.push(newProfessional);
      setSelectedProfessionals2(array);
    }
  }

  function handleRemoveProfessional(professional) {
    let array = [...selectedProfessionals];
    array.splice(array.indexOf(professional), 1);
    setSelectedProfessionals2(array);
  }

  console.log(initialValues)

  function handleSubmit(values) {
    if (!isLoading) {
      setInitialValues(values);
      let array = [];
      let revaluation = '';
      // console.log(values.previsao);
      if(values.previsao) {
        array = values.previsao.length ? values.previsao.split('-') : [];
        revaluation = values.previsao.length ? `${array[2]}.${array[1]}.${array[0]}` : '';
      }
      const data = selectedPatient.split('-');
      const altas = selectedProfessionals.map(professional => professional.id);
      const schedules = []
      // const schedules = s.map(attendance => ({
      //   PAF_AREA: attendance.id,
      //   PAF_FREQUENCIA: attendance.frequency,
      //   PAF_DOW: attendance.day,
      //   PAF_HORINI: `${attendance.initialHour}:${attendance.initialMinute}:00`,
      //   PAF_HORFIM: `${attendance.finalHour}:${attendance.finalMinute}:00`
      // }));

      const familiars = selectedFamiliars.map(familiar => ({
        P_PT_F_GRAU_PARENTESCO: familiar.id,
        P_PT_F_FAMILIAR: familiar.name
      }));
      const professionals = selectedProfessionals2.map(professional => professional.id);
      const obj = {
        P_PT_R_OBJT_DESENV_ASPCT_INDIVI: values.aspectosIndividuais.join('|'),
        P_PT_R_OBJT_ATIVIDA_VIDA_DIARIA: values.atividadesDiarias.join('|'),
        P_PT_R_OBJT_ORGANIZCAO_FAMILIAR: values.organizacaoFamiliar.join('|'),
        P_PT_R_OBJT_SAUDE: values.saude.join('|'),
        P_PT_R_OBJT_SITUACAO_EMOCIONAL: values.situacaoEmocional.join('|'),
        P_PT_R_OBJT_FATORES_SOCIAIS: values.fatoresSociais.join('|'),
        P_PT_R_OBS: values.notes,
        P_PT_R_ALTA_AREA: values.alta == '0' ? 1 : 0,
        P_PT_R_ALTA_INSTITUCIONAL: values.alta == '1' ? 1 : 0,
        P_PT_R_PREVISAO_REAVALIACAO: revaluation,
        P_PT_R_ALTAS: altas,
        P_PT_R_AGENDAMENTOS: schedules,
        P_PT_R_FAMILIARES: familiars,
        P_PT_R_PROFISSIONAIS: professionals
      };
      setIsLoading(true);
      let date = false;
      const dateArray = values.date.split('-')
      if(editDate) {
        date = editDate.replace('/', '.').replace('/', '.');
      } else {
        date = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
      }
      setReavaliacao(data[0], data[1], obj, date).then(json => {
        setIsLoading(false);
        if (json.error) {
          danger(`Ocorreu um erro ao enviar os dados da reavaliação: ${json.message}`);
        } else {
          success('Dados enviados com sucesso!');
          print();
          navigate('/user');
        }
      });
    }
  }
  return (
    <Formik
      // validationSchema={schema}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, { resetForm }) => handleSubmit(values)}
    >
      {({ values, errors, handleChange, setFieldValue }) => {
        return (
          <Form disabled={!actions.some(action => action.id === 701)}>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolveu/aprimorou aspectos individuais</label>
                <RadioRow>
                  {responses.aspectosIndividuais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <div className="bottonLine" />
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">
                  Desenvolveu/aprimorou a execução das atividades do cotidiano
                </label>
                <RadioRow>
                  {responses.atividadesDiarias.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolveu/aprimorou a organização familiar</label>
                <RadioRow>
                  {responses.organizacaoFamiliar.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolveu/aprimorou a saúde</label>
                <RadioRow>
                  {responses.saude.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolveu/aprimorou a condição emocional</label>
                <RadioRow>
                  {responses.situacaoEmocional.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>
            <FormRow>
              <div className="fullInput animeDown radioGroup">
                <label className="highlight">Desenvolveu/aprimorou fatores sociais</label>
                <RadioRow>
                  {responses.fatoresSociais.map(response => (
                    <RadioOrCheck
                      key={response.value}
                      response={response}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                </RadioRow>
              </div>
            </FormRow>

            <TitleSection>Observações</TitleSection>
            <FormRow>
              <div className="fullInput">
                <FormField
                  as="textarea"
                  name="notes"
                  type="text"
                  onChange={handleChange}
                  maxLength={256}
                  defaultValue={initialValues.notes}
                />
              </div>
            </FormRow>
            <TitleSection>Data da reavaliação</TitleSection>
            <FormRow>
              <div className="fullInput">
                <FormField
                  name="date"
                  type="date"
                  max={`${currentDate.getFullYear()}-${addZero(
                    currentDate.getMonth() + 1
                  )}-${addZero(currentDate.getDate())}`}
                />
              </div>
            </FormRow>
            <TitleSection>Previsão de reavaliação do projeto terapêutico</TitleSection>
            <FormRow>
              <div className="fullInput">
                <FormField
                  name="previsao"
                  type="date"
                  min={`${currentDate.getFullYear()}-${addZero(
                    currentDate.getMonth() + 1
                  )}-${addZero(currentDate.getDate())}`}
                  max="2999-12-31"
                />
              </div>
            </FormRow>

            <TitleSection>Componentes Familiares que Participaram</TitleSection>
            <FormRow style={{ marginBottom: 30 }}>
              <Select>
                <select
                  onChange={e => setSelectedFamiliar(e.target.value)}
                  value={selectedFamiliar}
                >
                  {relationships.map(relationship => {
                    return (
                      <option key={relationship.id} value={relationship.id}>
                        {relationship.label}
                      </option>
                    );
                  })}
                </select>
                <AddButton type="button" primary onClick={handleAddFamiliar}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            {selectedFamiliars.length > 0 ? (
              selectedFamiliars.map(familiar => (
                <AttendanceRow className="animeUp" style={{ maxHeight: 50 }}>
                  <RemoveButton
                    type="button"
                    className="animeUp"
                    primary
                    onClick={() => handleRemoveFamiliar(familiar)}
                  >
                    <HiUserRemove size={30} color="#C61E24" />
                  </RemoveButton>
                  <FamiliarType key={familiar.id}>{familiar.label}</FamiliarType>
                  <FamiliarInput
                    type="text"
                    value={familiar.name}
                    onChange={e => handleChangeFamiliar(familiar, e.target.value)}
                  />
                </AttendanceRow>
              ))
            ) : (
              <EmptyContent>Sem Familiares adicionados</EmptyContent>
            )}
            <TitleSection>Profissionais que participaram</TitleSection>
            <FormRow style={{ marginBottom: 10 }}>
              <Select>
                <select
                  onChange={e => setSelectedProfessional(e.target.value)}
                  value={selectedProfessional}
                >
                  {professionals.map(professional => {
                    return (
                      <option key={professional.id} value={professional.id}>
                        {professional.name}
                      </option>
                    );
                  })}
                </select>
                <AddButton type="button" primary onClick={handleAddProfessional}>
                  <BsPlus size={30} color="#fff" />
                </AddButton>
              </Select>
            </FormRow>
            <FormRow style={{ flexDirection: 'column' }}>
              {selectedProfessionals2.length > 0 ? (
                selectedProfessionals2.map(professional => (
                  <AttendanceRow className="animeUp mediumInput" style={{ maxHeight: 50 }}>
                    <RemoveButton
                      type="button"
                      className="animeUp"
                      primary
                      onClick={() => handleRemoveProfessional(professional)}
                    >
                      <HiUserRemove size={30} color="#C61E24" />
                    </RemoveButton>
                    <FamiliarType key={professional.id}>{professional.name}</FamiliarType>
                  </AttendanceRow>
                ))
              ) : (
                <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
              )}
            </FormRow>
            <TitleSection>Situação</TitleSection>
            <FormRow style={{ marginTop: 30 }}>
              <div className="mediumInput animeDown radioGroup">
                <RadioRow>
                  <label>
                    <FormField type="radio" name="alta" value={`0`} />
                    <span className="checkMark"></span>Alta de setor
                  </label>
                  <label>
                    <FormField type="radio" name="alta" value={`1`} />
                    <span className="checkMark"></span>Alta Institucional
                  </label>
                </RadioRow>
              </div>
            </FormRow>
            <FormRow style={{ marginBottom: 10 }}>
              <div>
                <label>Responsáveis pela alta</label>
                <select
                  onChange={e => setSelectedProfessional(e.target.value)}
                  value={selectedProfessional}
                >
                  {professionals.map(professional => (
                    <option key={professional.id} value={professional.id}>
                      {professional.name}
                    </option>
                  ))}
                </select>
              </div>
              <AddButton
                style={{ marginTop: 20 }}
                type="button"
                primary
                onClick={() => handleAddAttendance(false)}
              >
                <BsPlus size={30} color="#fff" />
              </AddButton>
            </FormRow>

            <FormRow style={{ marginBottom: 20, flexDirection: 'column' }}>
              {selectedProfessionals.length > 0 ? (
                selectedProfessionals.map(professional => {
                  return (
                    <AttendanceRow key={professional.id} className="animeUp">
                      <label style={{ minWidth: 280 }}>
                        Área {professional.sector}: {professional.name} {professional.class}
                      </label>
                      <AddButton
                        type="button"
                        className="animeUp"
                        primary
                        onClick={() => handleRemoveAttendance(professional, false)}
                      >
                        <BsTrash size={20} color="#fff" />
                      </AddButton>
                    </AttendanceRow>
                  );
                })
              ) : (
                <EmptyContent className="animeUp">Nenhum Profissional Adicionado</EmptyContent>
              )}
            </FormRow>
            {/* <FormRow style={{ marginBottom: 10 }}>
              <div>
                <label style={{ width: 130 }}>Será atendido por</label>
                <select
                  onChange={e => setSelectedAttendance(e.target.value)}
                  value={selectedAttendance}
                >
                  {areas.map(area => {
                    return (
                      <option key={area.id} value={area.id}>
                        {area.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <AddButton
                style={{ marginTop: 20 }}
                type="button"
                primary
                onClick={() => handleAddAttendance(true)}
              >
                <BsPlus size={30} color="#fff" />
              </AddButton>
            </FormRow>
            <FormRow style={{ marginBottom: 20, flexDirection: 'column' }}>
              {selectedAttendances.length > 0 ? (
                selectedAttendances.map(attendance => {
                  return (
                    <AttendanceRow key={attendance.id} className="animeUp">
                      <label style={{ minWidth: 200 }}>{attendance.label}</label>
                      <Select className="animeLeft">
                        <select
                          defaultValue={attendance.frequency}
                          onChange={e =>
                            handleChangeAttendance(attendance, 'frequency', e.target.value)
                          }
                        >
                          {frequencies.map(frequency => (
                            <option key={frequency.id} value={frequency.id}>
                              {frequency.label}
                            </option>
                          ))}
                        </select>
                      </Select>
                      <Select>
                        <select
                          defaultValue={attendance.day}
                          onChange={e => handleChangeAttendance(attendance, 'day', e.target.value)}
                        >
                          <option value={2}>Segunda</option>
                          <option value={3}>Terça</option>
                          <option value={4}>Quarta</option>
                          <option value={5}>Quinta</option>
                          <option value={6}>Sexta</option>
                          <option value={7}>Sábado</option>
                          <option value={1}>Domingo</option>
                        </select>
                      </Select>
                      <Select className="time animeLeft">
                        <select
                          defaultValue={attendance.initialHour}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'initialHour', e.target.value)
                          }
                        >
                          {hours.map(hour => (
                            <option value={hour.value}>{hour.label}</option>
                          ))}
                        </select>
                        :
                        <select
                          defaultValue={attendance.initialMinute}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'initialMinute', e.target.value)
                          }
                        >
                          {minutes.map(minute => (
                            <option value={minute.value}>{minute.label}</option>
                          ))}
                        </select>
                      </Select>
                      até
                      <Select className="time animeLeft">
                        <select
                          defaultValue={attendance.finalHour}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'finalHour', e.target.value)
                          }
                        >
                          {hours.map(hour => (
                            <option value={hour.value}>{hour.label}</option>
                          ))}
                        </select>
                        :
                        <select
                          defaultValue={attendance.finalMinute}
                          className="number"
                          onChange={e =>
                            handleChangeAttendance(attendance, 'finalMinute', e.target.value)
                          }
                        >
                          {minutes.map(minute => (
                            <option value={minute.value}>{minute.label}</option>
                          ))}
                        </select>
                      </Select>
                      <AddButton
                        type="button"
                        className="animeUp"
                        primary
                        onClick={() => handleRemoveAttendance(attendance, true)}
                      >
                        <BsTrash size={20} color="#fff" />
                      </AddButton>
                    </AttendanceRow>
                  );
                })
              ) : (
                <EmptyContent className="animeUp">Nenhum Atendimento Adicionado</EmptyContent>
              )}
            </FormRow> */}
            <FormRow>
              <SubmitButton primary type="submit">
                {isLoading ? (
                  <ReactLoading type="spinningBubbles" color="#fff" height={30} width={30} />
                ) : 'Enviar Dados'}
              </SubmitButton>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
}
